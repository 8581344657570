// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-icons/font/bootstrap-icons.css';

// import the third-party stylesheets directly from your JS
//@import 'bootstrap/dist/css/bootstrap.css';
//@import 'bootstrap-icons/font/bootstrap-icons.css'; // needs additional webpack config!

//@import '@fullcalendar/core/main.css';
//@import '@fullcalendar/daygrid/main.css';

html {
    min-height: 100vh !important;
    height: 100vh;
}

body {
    background-color: #336600;
    min-height: 100vh !important;
    height: 100vh;
}
div#app {
    min-height: 100vh !important;
    height: 100vh;
}

.navbar {
    background-color: #ddd;
}

main.container {
    position: relative;    
    top: 3.6em;
}

/*div.background_image {
    position: fixed;
    display: block;
    height: 100vh;
    background-color: white;
}
/*main.container:before {
    content: ' ';
    display: fixed;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    background-image: url("/images/logo_nhc.svg");
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: auto;
    z-index: -1;
}*/
div.background-wrap {
    position: relative;
    min-height: 100vh !important;
    height: 100vh;
}
div.background-wrap:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    background-image: url("/images/logo_nhc.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 80% 80%;
    background-color: white;
}
.container-content {
    background-color: rgba($color: white, $alpha: .95) !important;
    position: relative;
    min-height: 100vh !important;
    height: 100vh;
    max-height: 100vh;    
}

// calendar css
:root {
    --fc-border-color: #888888;
}
.fc-day-today {
    border: solid #336600 3px !important;
}